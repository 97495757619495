/* Contact Section */
#contact {
  padding-left: 20px !important;
  padding-right: 20px !important;
  background: linear-gradient(
    to right,
    var(--color-blue) 0%,
    var(--color-light-blue) 100%
  );
  color: var(--color-darkwhite);
}
#contact .section-title {
  margin-bottom: 40px;
  text-align: left;
}
#contact .section-title p {
  font-size: 16px;
}

#contact h2 {
  color: var(--color-darkwhite);
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}
#contact h3 {
  color: var(--color-darkwhite);
  margin-top: 80px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
}
#contact form {
  padding-top: 20px;
}
#contact .text-danger {
  color: var(--color-red);
  text-align: left;
}
label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  float: left;
}
#contact .form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: var(--color-grey);
  background-color: var(--color-darkwhite);
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control:-moz-placeholder {
  color: #777;
}
.form-control::-moz-placeholder {
  color: #777;
}
.form-control:-ms-input-placeholder {
  color: #777;
}

#contact .contact-item {
  margin: 20px 0;
}
#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}
#contact .contact-item i.fa {
  margin-right: 10px;
}

#contact .btn-custom {
  margin: 30px 0;
  background: transparent;
  border: 2px solid var(--color-darkwhite);
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: var(--color-darkwhite);
  padding: 14px 34px;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: var(--color-darkwhite);
  background-image: none;
  background-color: var(--color-light-blue);
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#contact .btn-custom:hover {
  color: var(--color-blue);
  background: var(--color-darkwhite);
}

.google-maps-link,
.email_link,
.phonenumber_link {
  text-decoration: none;
  color: var(--color-darkwhite);
}

.google-maps-link:hover,
.phonenumber_link:hover,
.email_link:hover {
  text-decoration: underline;
  color: var(--color-darkwhite);
}

.google-maps-link:visited,
.phonenumber_link:focus,
.email_link:focus {
  color: var(--color-darkwhite);
}

.social-bar {
  margin-top: 3em;
}

.contact-info {
  margin-top: -3.5em;
}

@media only screen and (min-width: 786px) {
  .social-bar {
    top: 0em;
  }

  .contact-info {
    margin-top: 0em;
  }
}
