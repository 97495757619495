.drawer {
  display: block;
  position: relative;
  width: 23em;
}

.design-element-right {
  display: block;
  position: fixed;
  background-color: var(--color-blue);
  top: 0%;
  left: 22.3em;
  width: 0.7em;
  height: 100%;
  z-index: 100;
}

.pages-typography {
  color: var(--color-grey);
  width: 0%;
  transition-property: width;
  transition-duration: 0.18s;
  transition-timing-function: ease-out;
  padding-top: 8;
  padding-bottom: 8;
}

.pages-typography:hover {
  background: linear-gradient(
    to right,
    var(--color-light-blue) 0%,
    var(--color-blue) 100%
  );
  color: whitesmoke;
  width: 97%;
}

.pages-list-drawer {
  margin-top: 4em;
}

.page-scroll {
  display: inline;
  position: static;
  right: 3.5px;
}

.navigation-menu-typography {
  font-family: "LogoFont";
  font-size: 21;
  padding-left: 2em;
  padding-right: 13em;
}

.logo-img-container-mobile {
  margin-top: 0.5em;
  margin-left: 1.75em;
}

.logo-img-container-mobile img {
  height: 50;
}
