.project-card {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-align: center;
  padding: 20px;
  color: var(--color-blue);
}

.project-card:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.project-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--project-background);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.3;
}

.project-detail-box {
}

.chip {
  background-color: var(--color-light-blue);
  background-image: linear-gradient(
    to right,
    var(--color-blue) 0%,
    var(--color-light-blue) 100%
  );
  font-family: "Raleway", sans-serif;
  color: var(--color-darkwhite);
  letter-spacing: 1px;
  font-size: 10pt;
  font-weight: 500;
  border-radius: 25px;
  border: 0;
  padding: 10px 10px;
  margin-right: 5px;
}

.chip-avatar {
  fill: var(--color-darkwhite);
  padding-right: 5px;
}

.project-detail-box-technologies {
  padding: 30px;
}
