@media (min-width: 768px) {
  .div-contact-buttons {
    column-gap: 3rem;
  }

  .cta-button {
    padding: 14px 64px;
    bottom: 3vh;
  }
}
