#footer {
  background-color: var(--color-blue);
  background: linear-gradient(to right, var(--color-grey) 0%, #555555 100%);
  margin: 0;
  padding-top: 30px;
  color: var(--color-darkwhite);
}

#copyright {
  color: var(--color-blue);
}

#footer a {
  color: var(--color-darkwhite);
}
#footer a:hover {
  text-decoration: underline;
}

#footer ul,
#footer ol {
  list-style: none;
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
