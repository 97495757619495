#service {
  transition: all 0.5s ease 0s;
}
#services .service-desc {
  margin: 10px 10px 20px;
}
#services h2 {
  color: var(--color-darkwhite);
}
#services .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#services svg {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(
    to right,
    var(--color-light-blue) 0%,
    var(--color-blue) 100%
  );
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}
#services p {
  color: var(--color-darkwhite);
}
#services .service-desc {
  margin-bottom: 40px;
}

#services svg:hover {
  background: linear-gradient(
    to right,
    var(--color-light-blue) 20%,
    var(--color-blue) 100%
  );
}

.service-item {
  cursor: pointer;
}

.service-item:hover {
  animation: pulse 0.5s 1;
}

.service-item a {
  color: var(--color-darkwhite);
  text-decoration: underline;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.95);
  }
  20% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(0.85);
  }
  40% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(0.85);
  }
  80% {
    transform: scale(0.9);
  }
  90% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
