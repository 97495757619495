#testimonials-container {
  padding-left: 0 !important;
}
#testimonials {
  text-align: center;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--color-blue);
}

#testimonials:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--testimonial-background);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.2;
}

.arrow-icon-container {
  cursor: pointer;
  height: 100%;
}
.arrow-icon-container .arrow-icon {
  font-size: 28pt;
}
.arrow-icon-container:hover .arrow-icon,
.arrow-icon-container .arrow-icon:hover {
  transform: scale(1.2);
}
