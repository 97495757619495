.member-card-left-member-card-left {
  display: block;
  position: relative;
  min-height: auto;
  width: 48%;
  min-width: auto;
  align-self: flex-start;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.member-card-left-card-left {
  display: block;
  width: 100%;
  position: relative;
  box-shadow: 8px 6px 5px 2px #d4d4d4;
  margin-top: 4rem;
  /*min-height: 13rem;*/
  height: auto;
  border-radius: 15px;
  background-image: linear-gradient(
    90deg,
    rgb(223, 241, 253) 1%,
    rgb(139, 193, 246) 100%
  );
}
.member-card-left-name-left {
  position: absolute;
  left: 6rem;
  z-index: 101;
  font-size: 24px; /* 18px */
  margin-left: 6rem;
  margin-top: -2rem;
}
.member-card-left-job-title-left {
  position: absolute;
  top: 1rem;
  left: 12rem;
  width: 55%;
  z-index: 100;
  font-size: 13px; /* 11px */
  margin-top: 0.3rem;
  font-weight: 500 bold;
  text-align: left;
}
.member-card-left-image-left {
  position: absolute;
  top: -3rem;
  left: -2rem;
  width: 107px;
  height: 140px;
  transform: rotate(-11.9deg);
  box-shadow: 1px 2px 4px 2px #a3a3a3;
  object-fit: cover;
  border-color: white;
  border-width: 8px;
  border-radius: 16px;
  border-style: solid;
}
.member-card-left-quotation-left {
  display: block;
  position: relative;
  margin-left: 4rem;
  margin-top: -10rem;
  z-index: 250;
  font-size: 15px;
  padding-top: 6rem;
  padding-left: 8rem;
  padding-right: 6rem;
  padding-bottom: 6rem;
  text-align: left;
  height: auto;
}
.member-card-left-container-left {
  position: absolute;
  width: 13rem;
  height: 14rem;
  z-index: 1;
  transform: scale(120%) rotate(18deg);
  filter: blur(2px);
  right: 0.2rem;
  bottom: 0.4rem;
}
.member-card-left-container1-left {
  display: contents;
}
.member-card-left-root-class-name {
  width: 100%;
}
.member-card-left-root-class-name1 {
  width: 100%;
}
@media (max-width: 1025px) {
  .member-card-left-card-left {
    height: auto;
    width: 150%;
    max-width: 200rem;
  }
  .member-card-left-job-title-left {
    z-index: 101;
  }
  .member-card-left-quotation-left {
    padding-right: 3rem;
  }
  .member-card-left-container-left {
    transform: scale(88%) rotate(18deg);
    margin-right: -0.7rem;
    margin-bottom: -0.7rem;
  }
}
@media (max-width: 770px) {
  .member-card-left-member-card-left {
    height: auto;
    width: 55%;
    min-width: 25.5rem;
  }
  .member-card-left-card-left {
    min-height: 15rem;
  }
  .member-card-left-job-title-left {
    z-index: 100;
  }
  .member-card-left-quotation-left {
    padding-right: 6rem;
  }
  .member-card-left-container-left {
    transform: scale(95%) rotate(18deg);
    margin-right: -0.3rem;
    margin-bottom: -0.3rem;
  }
}
@media (max-width: 480px) {
  .member-card-left-member-card-left {
    height: auto;
    width: 66%;
    min-width: 14.5rem;
  }
  .member-card-left-card-left {
    min-height: 18rem;
  }

  .member-card-left-quotation-left {
    font-size: 13px;
  }
}
@media (max-width: 320px) {
  .member-card-left-card-left {
    min-height: 23rem;
  }
  .member-card-left-quotation-left {
    width: 94%;
    padding-right: 5rem;
  }
}
