.feature-box {
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 8px;
  padding-right: 8px;
  background-size: cover;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  text-align: center;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feature-1 {
  background-image: linear-gradient(
    to bottom right,
    rgba(4, 255, 0, 0.463),
    rgb(0, 162, 255)
  );
}
.feature-2 {
  background-image: linear-gradient(to bottom right, #5e2028, rgb(0, 157, 255));
}
.feature-3 {
  background-image: linear-gradient(to bottom right, red, yellow);
}

.feature-box:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.feature-button {
  margin-top: 5vh;
  border: 2px solid #ffffff;
  border-radius: 50px;
  color: #ffffff;
  display: inline-block;
  padding-left: 3vh;
  padding-right: 3vh;
  width: 80%;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.5s linear;
}

.feature-button:hover {
  border: 4px solid #ffffff;
}

.feature-box-icon svg {
  width: 15vh;
  height: 15vh;
}

.feature-card-seperator {
  margin-top: 4vh;
  margin-bottom: 4vh;
  width: 80%;
  height: 1px;
  background: #ffffff;
}
