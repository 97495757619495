#header {
  margin: 0 auto;
  position: relative;
  z-index: 1;
  height: 88vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#header::before {
  background: var(--header-background);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
}

.div-company-logo {
  position: relative;
  margin: 0 auto;
  margin-bottom: 28rem;
  margin-top: 0em;
  width: 100%;
  height: 0em;
}

.div-contact-buttons {
  column-gap: 8rem;
}

.div-header-buttons {
  row-gap: 2rem;
}

.cta-button {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--color-light-blue);
  background-image: linear-gradient(
    to right,
    var(--color-light-blue) 0%,
    var(--color-blue) 100%
  );
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
  padding: 14px 74px;
  margin: 20px;
  margin-bottom: 0em;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}

.cta-button:hover,
.cta-button:focus,
.cta-button.focus,
.cta-button:active,
.cta-button.active {
  color: #fff;
  background-image: none;
  background-color: var(--color-light-blue);
}

.col-xs-12 {
  position: relative;
  top: 2em;
}
