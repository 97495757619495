.fab_container{
    position: fixed;
    right: 2em;
    bottom: 2em;
    z-index: 10;
}

.floating_action_button{
    background-color: var(--color-light-blue);
    background-image: linear-gradient( to right, var(--color-light-blue) 0%, var(--color-blue) 100% );
    font-size:larger;
    transform: scale(1.5);
}