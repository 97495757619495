/* Landscape */
@media (orientation: landscape) and (max-height: 400px) {
  .div-company-logo {
    margin-bottom: 18rem;
  }

  .cta-button {
    padding: 14px 64px;
    margin: 23px;
    bottom: 20vh;
  }
}
