#features {
  margin-top: 8em;
}

@media (min-width: 768px) {
  #features {
    margin-top: 0em;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}
