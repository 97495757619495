@font-face {
  font-family: "LogoFont";
  src: url("./../../../../../public/fonts/LogoFont/LogoFont.otf")
    format("opentype");
}

.app-bar {
  position: sticky;
  height: 60px;
  transition: "top 1.0s";
}

.toolbar {
  min-width: 100%;
  flex-direction: inherit;
  justify-content: space-between;
}

.menu-bar {
  background-color: white;
  box-shadow: 2px;
}

.menu-icon {
  color: grey;
  width: 45px;
  height: 40px;
  border-style: solid;
  border-radius: 5px;
  border-width: 0.5px;
  border-color: rgb(179, 179, 179);
  padding: 5px;
}

.menu-icon:hover {
  color: rgb(60, 92, 167);
  border-color: rgb(60, 92, 167);
}

.design-element-md {
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #2e5785;
  top: 56px;
  margin-left: -15px;
}

.buttonsurface {
  display: block;
  position: relative;
  opacity: 85%;
  height: 100%;
  top: 30%;
  transition-duration: 0.28s;
  transition-timing-function: top ease-out;
  color: black;
  padding-top: 6px;
  padding-bottom: 28px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
}

.buttonsurface:hover {
  background: linear-gradient(
    to bottom,
    var(--color-light-blue) 0%,
    var(--color-blue) 70%
  );
  width: 100%;
  top: 13;
  color: whitesmoke;
}

.buttonsurface-typopgraphy {
  font-size: 20;
  font-family: "LogoFont";
}

.logo-img-container img {
  height: 30;
}

@media only screen and (min-width: 600px) {
  .menu-bar {
    background-color: white;
    box-shadow: 2px;
  }

  .menu-icon {
    color: grey;
    width: 45px;
    height: 40px;
    border-style: solid;
    border-radius: 5px;
    border-width: 0.5px;
    border-color: rgb(179, 179, 179);
    padding: 5px;
  }

  .menu-icon:hover {
    color: rgb(60, 92, 167);
    border-color: rgb(60, 92, 167);
  }

  .design-element-md {
    display: block;
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: #2e5785;
    top: 59px;
    margin-left: -25px;
  }

  .buttonsurface {
    display: block;
    position: relative;
    opacity: 85%;
    height: 100%;
    top: 30%;
    transition-duration: 0.28s;
    transition-timing-function: top ease-out;
    color: var(--color-grey);
    padding-top: 6px;
    padding-bottom: 28px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
  }

  .buttonsurface:hover {
    background: linear-gradient(
      to bottom,
      var(--color-light-blue) 0%,
      var(--color-blue) 70%
    );
    width: 100%;
    top: 13;
    color: white;
  }
}
