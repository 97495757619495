#team .team-img {
  height: 275px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  width: 210px;
  object-fit: "cover";
}

#team .anonymous {
  /*width: 80%;*/
  width: 210px;
  height: 275px;
  object-fit: "cover";
}

.team-member-pre-box {
  text-align: center;
}

.team-member-box {
  background-color: var(--color-darkwhite);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 250px;
  height: 385px;
  margin: 0 auto;
}

.member_name_container {
  display: flex;
  flex-direction: "column";
  margin: "auto";
}

.member-name {
  padding: 0px;
  margin: 0px;
}
