.member-card-right-member-card-right {
  display: block;
  position: relative;
  float: right;
  min-height: auto;
  width: 48%;
  min-width: auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  margin-right: 0rem;
}
.member-card-right-card-right {
  display: block;
  width: 100%;
  position: relative;
  box-shadow: 8px 6px 5px 2px #d4d4d4;
  margin-top: 4rem;
  /*min-height: 13rem;*/
  height: auto;
  border-radius: 15px;
  background-image: linear-gradient(
    270deg,
    rgb(223, 241, 253) 1%,
    rgb(139, 193, 246) 100%
  );
}
.member-card-right-name-right {
  position: absolute;
  right: 12rem;
  z-index: 101;
  font-size: 24px; /* 18px */
  margin-left: 6rem;
  margin-top: -2rem;
  text-align: right;
}
.member-card-right-job-title-right {
  position: absolute;
  top: 1rem;
  right: 12rem;
  width: 55%;
  z-index: 100;
  font-size: 13px; /* 11px */
  margin-top: 0.3rem;
  font-weight: 500 bold;
  text-align: right;
}
.member-card-right-image-right {
  position: absolute;
  top: -3rem;
  right: -2rem;
  width: 107px;
  height: 140px;
  transform: rotate(11.9deg);
  box-shadow: 1px 2px 4px 2px #a3a3a3;
  object-fit: cover;
  border-color: white;
  border-width: 8px;
  border-radius: 16px;
  border-style: solid;
}
.member-card-right-quotation-right {
  display: block;
  position: relative;
  margin-right: 9rem;
  margin-top: -10rem;
  z-index: 250;
  font-size: 15px;
  padding-top: 6rem;
  padding-left: 4rem;
  padding-right: 6rem;
  padding-bottom: 6rem;
  text-align: right;
  height: auto;
}
.member-card-right-container {
  position: absolute;
  width: 13rem;
  height: 14rem;
  z-index: 1;
  transform: scale(120%) rotate(-18deg);
  filter: blur(2px);
  left: 0.2rem;
  bottom: 0.4rem;
}
.member-card-right-container1 {
  display: contents;
}
.member-card-right-root-class-name {
  width: 100%;
}
.member-card-right-root-class-name1 {
  width: 100%;
}
@media (max-width: 1025px) {
  .member-card-right-member-card-right {
    width: 69%;
  }

  .member-card-right-card-right {
    height: auto;
    max-width: 200rem;
  }
  .member-card-right-job-title-right {
    z-index: 101;
  }
  .member-card-right-quotation-right {
    padding-right: 3rem;
  }
  .member-card-right-container {
    transform: scale(88%) rotate(18deg);
    margin-right: -0.7rem;
    margin-bottom: -0.7rem;
  }
}
@media (max-width: 770px) {
  .member-card-right-member-card-right {
    height: auto;
    width: 85%;
    min-width: 25.5rem;
  }
  .member-card-right-card-right {
    min-height: 15rem;
  }
  .member-card-right-job-title-right {
    z-index: 100;
  }
  .member-card-right-quotation-right {
    padding-right: 3.3rem;
  }
  .member-card-right-container {
    transform: scale(95%) rotate(18deg);
    margin-right: -0.3rem;
    margin-bottom: -0.3rem;
  }
}
@media (max-width: 480px) {
  .member-card-right-member-card-right {
    height: auto;
    width: 100%;
    min-width: 14.5rem;
  }
  .member-card-right-card-right {
    min-height: 18rem;
  }

  .member-card-right-quotation-right {
    font-size: 13px;
  }
}
@media (max-width: 320px) {
  .member-card-right-card-right {
    min-height: 23rem;
    width: 100%;
  }
  .member-card-right-quotation-right {
    width: 94%;
    padding-right: 5rem;
  }
}
